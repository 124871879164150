/**
 * @stefano / 04-05-2020
 * Questo componente viene usato anche nel riepilogo per il pagamento.
 * E' stato chiesto che venga aggiunta una voce nel riepilogo per il pagamento da aggiungere al totale da mostrare, se
 * l'utente ha scelto di non pagare nesun acconto.
 * Quindi qui sotto includo AppContext e le le configs per vedere se sono nel riepilogo e se si aggiungo una cifra al
 * totale e al riepilogo
 * */
import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Avatar from '@material-ui/core/Avatar'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
// aggiungo AppContext e PaymentContext e diversi configs
import {
  participationsLookup,
  aggiungiAccontoForzatoPrice,
  paymentsINPS,
} from '../../main/specificUtils/configs'
import {AppContext} from '../../contexts/AppContext'
import {PaymentContext} from 'contexts/PaymentContext'
import {PackageSelectorContext} from '../../contexts/PackageSelectorContext'
import {SpendingContext} from 'contexts/SpendingContext'
import {AlertTitle} from '@material-ui/lab'

// bottom bar mobile animation
const enlargeBotBarHandler = () => {
  const panels = document.querySelectorAll('.aside-panel')
  const formSidebar = document.querySelector('.form-sidebar')
  const expandIcon = document.querySelector('.expand-icon')
  const priceSpecs = document.querySelector('.price-specs')

  for (var i = 0; i < panels.length; i++) {
    // panels and price specs show
    panels[i].classList.toggle('mobile-block-show')
  }
  priceSpecs.classList.toggle('mobile-grid-show')
  // sidebar expands
  formSidebar.classList.toggle('auto-height')
  // expand icon rotates
  expandIcon.classList.toggle('expand-icon-rotated')
}

function PriceDesc({price}) {
  return price === 0 ? '-' : `€${price}`
}

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  alert: {},
}))

export default function Price(props) {
  const classes = useStyles()
  // @stefano / 04-05-2020: setto appState e setto paymentState
  const [appState] = useContext(AppContext)
  const [paymentState] = useContext(PaymentContext)
  const [packageState] = useContext(PackageSelectorContext)
  const [spendingState] = useContext(SpendingContext)
  const {hasExtraPaymentServices, isVisualPrice} = paymentState

  const {
    isLoadingPrice,
    priceToPay,
    totalPrice,
    visualTotalPrice,
    packageId,
    basicCorePrice,
    departureService,
    departureCityLabel,
    departureServicePrice,
    structure,
    structureLabel,
    structurePrice,
    programTypology,
    programTypologyLabel,
    programTypologyPrice,
    contributoInpsLabel,
    bonusValueLabel,
    bonusLabel,
    extraServiceLabels,
    basicCoreCluster,
    step2Coupon,
  } = props

  let basicCorePriceDisplay = basicCorePrice

  let voucherResiduo = 0
  if (spendingState.vouchers) {
    voucherResiduo = spendingState.vouchers.reduce(function (accumulator, voucher) {
      return accumulator +
         (voucher.paymentMethodResidual - voucher.paymentMethodConsumed) +
         voucher.canApplyDiscount ? (voucher.valueDiscountResidual - voucher.valueDiscountConsumed) : 0
      }, 0)
  }

  let approximatePrice = false

  const pack =
    packageState.packages &&
    packageState.packages.find(p => p.value === packageState.package)

  if (pack) {
    approximatePrice = pack.approximatePrice === true
  }

  return (
    <React.Fragment>
      {!!basicCoreCluster && (
        <div className={classes.alert}>
          <Alert severity="info" color="success">
            Il tuo livello di appartenenza al GiocamondoClub è "
            {basicCoreCluster.name}"
          </Alert>
        </div>
      )}
      <div className="price-specs">
        {packageId != null && (
          <React.Fragment>
            <dt>Prezzo pacchetto:</dt>{' '}
            <dd>
              <PriceDesc price={basicCorePriceDisplay} />
            </dd>
          </React.Fragment>
        )}
        {departureService != null && (
          <React.Fragment>
            <dt>{departureCityLabel}:</dt>
            <dd>
              <PriceDesc price={departureServicePrice} />
            </dd>
          </React.Fragment>
        )}
        {Boolean(structure) && (
          <React.Fragment>
            {/*<dt>Struttura di destinazione:</dt>*/}
            <dt>Selezione:</dt>
            <dd>
              {' '}
              {structureLabel} €{structurePrice}
            </dd>
          </React.Fragment>
        )}
        {!!programTypology && (
          <React.Fragment>
            <dt>{programTypologyLabel}:</dt>
            <dd>
              <PriceDesc price={programTypologyPrice} />
            </dd>
          </React.Fragment>
        )}
        {Boolean(contributoInpsLabel) && (
          <React.Fragment>
            <dt>Contributo inps:</dt>
            <dd className="text-highlights">{contributoInpsLabel}</dd>
          </React.Fragment>
        )}
        {Boolean(bonusValueLabel) && (
          <React.Fragment>
            <dt>{bonusLabel}:</dt>
            <dd> {bonusValueLabel}</dd>
          </React.Fragment>
        )}
        {(!!spendingState.vouchers) && (
          spendingState.vouchers.map((voucher) => (
            <React.Fragment>
              {voucher.paymentMethodConsumed > 0 && (
                <>
                  <dt>{voucher.voucherDescription}:</dt>
                  <dd> € -{voucher.paymentMethodConsumed / 100}</dd>
                </>
              )}
              {voucher.valueDiscountConsumed > 0 && (
                <>
                  <dt>{voucher.discountDescription}:</dt>
                  <dd> € -{voucher.valueDiscountConsumed / 100}</dd>
                </>
              )}
              {voucher.percentDiscountConsumed > 0 && (
                <>
                  <dt>{voucher.discountDescription}:</dt>
                  <dd> ({voucher.discountPercent}%) € -{voucher.percentDiscountConsumed / 100}</dd>
                </>
              )}
            </React.Fragment>
          ))
        )}
        {voucherResiduo > 0 && (
          <small className="text-small text-danger">
            <span className="text-alert">
              <b>
                Può ancora usare {voucherResiduo / 100} € del credito dei
                suoi voucher.
              </b>
              <br />
            </span>
          </small>
        )}    
        {!!spendingState.helperMinusValue && (
          <React.Fragment>
            <dt>{spendingState.helperMinusDescription}:</dt>
            <dd> € {spendingState.helperMinusValue / 100}</dd>
          </React.Fragment>
        )}
        {!!spendingState.helperPlusValue && (
          <React.Fragment>
            <dt>{spendingState.helperPlusDescription}:</dt>
            <dd> € {spendingState.helperPlusValue / 100}</dd>
          </React.Fragment>
        )}
        {extraServiceLabels.map(es => (
          <React.Fragment key={es.id}>
            <dt className="dl-full" key={es.id}>
              {es.title}:{' '}
            </dt>
            <dd className="dl-full">
              {es.realvalue > 0 ? es.value : es.label + ' - '}{' '}
              {es.isVisualPrice && '**'}
            </dd>
          </React.Fragment>
        ))}
        {Boolean(bonusValueLabel) && (
          <small className="text-small text-danger">
            <span className="text-alert">*</span>
            La scontistica sarà subordinata alla verifica da parte della
            Giocamondo delle informazioni da lei inserite in fase di
            prenotazione.
          </small>
          /*<small className="text-small text-danger">
            <span className="text-alert">*</span>
            La scontistica applicata sarà subordinata alla verifica delle
            informazioni inserite. La Giocamondo si riserva, in ogni caso, anche
            successivamente all'uscita delle graduatorie (se partecipante al
            bando INPSieme) di adeguare la pratica qualora non si verificassero
            le condizioni necessarie per usufruire del bonus.
            <strong>
              NB: Anche successivamente all’uscita delle graduatorie.
            </strong>
          </small>*/
        )}

        {isVisualPrice && (
          <small className="text-small text-danger" style={{marginTop: 8}}>
            <span className="text-alert">**</span>
            Il costo del servizio non incide sul saldo della pratica, ma verrà
            venduto separatamente
          </small>
        )}
        {Boolean(step2Coupon) && (
          <small className="text-small text-danger">
            <span className="text-alert">*</span>
            La Giocamondo si riserva di controllare la correttezza del dato
            inserito
          </small>
          /*<small className="text-small text-danger">
            <span className="text-alert">*</span>
            La scontistica applicata sarà subordinata alla verifica delle
            informazioni inserite. La Giocamondo si riserva, in ogni caso, anche
            successivamente all'uscita delle graduatorie (se partecipante al
            bando INPSieme) di adeguare la pratica qualora non si verificassero
            le condizioni necessarie per usufruire del bonus.
            <strong>
              NB: Anche successivamente all’uscita delle graduatorie.
            </strong>
          </small>*/
        )}
      </div>

      <div className="sidebar-price-recap">
        {props.children}
        <div className="pricetag-wrapper">
          { (visualTotalPrice) && (
            <li className="big-text pk-price pricetag">
              <small>Costo al netto del contributo rimborsato da INPS:</small>
              {isLoadingPrice ? (
                <CircularProgress
                  className={clsx(classes.progress, 'progress-size')}
                />
              ) : (
                <span>
                  {' '}
                  €{visualTotalPrice < 0 ? 0 : visualTotalPrice / 100}
                </span>
              )}
            </li>
          )}
          <li className="big-text big-text2 pk-price pricetag">
            <small>Totale da Pagare:</small>
            {isLoadingPrice ? (
              <CircularProgress
                className={clsx(classes.progress, 'progress-size')}
              />
            ) : (
              <span>
                {' '}
                €{totalPrice < 0 ? 0 : totalPrice / 100}
              </span>
            )}
          </li>
          {approximatePrice && (appState.activeStep >= 4) && (appState.step2Participation === "2") && (
            <div className="aside-panel" style={{marginTop: '4px'}}>
              <Alert severity="warning">
                Nel ringraziarti per la richiesta di preventivo che stai inviando ai nostri uffici, ti ricordiamo che
                per i soggiorni individuali la disponibilità della camera viene verificata con la struttura
                alberghiera nel momento in cui il cliente manifesta la volontà di confermare la sua
                vacanza.
                Se vuoi attendere l'uscita delle graduatorie INPS, la tua prenotazione verrà lavorata dal
                nostro ufficio all'uscita delle graduatorie se risulterai vincitore del contributo.
                Se intendi partire a prescindere dal contributo INPS, ti consigliamo di confermare subito la
                tua prenotazione selezionando di seguito "Si, Parto Sicuro". In questo caso i nostri uffici
                lavoreranno subito la tua richiesta verificando la disponibilità della camera.
              </Alert>
            </div>
          )}
          <Avatar
            onClick={enlargeBotBarHandler}
            className="expand-icon highlight-color">
            <ExpandLess />
          </Avatar>
        </div>
      </div>
    </React.Fragment>
  )
}

Price.propTypes = {
  isLoadingPrice: PropTypes.bool.isRequired,
  priceToPay: PropTypes.number.isRequired,
  visualTotalPrice: PropTypes.number.isRequired,
  packageId: PropTypes.any,
  basicCorePrice: PropTypes.number.isRequired,
  departureService: PropTypes.any,
  departureCityLabel: PropTypes.string.isRequired,
  departureServicePrice: PropTypes.number.isRequired,
  structure: PropTypes.any,
  structureLabel: PropTypes.string.isRequired,
  structurePrice: PropTypes.number.isRequired,
  programTypology: PropTypes.any,
  programTypologyLabel: PropTypes.string.isRequired,
  programTypologyPrice: PropTypes.number.isRequired,
  contributoInpsLabel: PropTypes.string.isRequired,
  bonusValueLabel: PropTypes.string.isRequired,
  bonusLabel: PropTypes.string.isRequired,
  extraServiceLabels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  basicCoreCluster: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
}
