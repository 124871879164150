import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {participationsLookup} from '../../main/specificUtils/configs'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    PayPalButtons,
    PayPalCardFieldsForm,
    PayPalCardFieldsProvider, PayPalCVVField, PayPalExpiryField, PayPalNameField, PayPalNumberField,
    PayPalScriptProvider,
    usePayPalCardFields,
} from "@paypal/react-paypal-js";
import {AppContext} from 'contexts/AppContext'

// PayPal
const currency = "EUR";

const SubmitPayment = ({isPaying, setIsPaying}) => {
    const {cardFieldsForm, fields} = usePayPalCardFields();

    const handleClick = async () => {
        if (!cardFieldsForm) {
            const childErrorMessage =
                "Unable to find any child components in the <PayPalCardFieldsProvider />";

            throw new Error(childErrorMessage);
        }
        const formState = await cardFieldsForm.getState();

        if (!formState.isFormValid) {
            return alert("The payment form is invalid");
        }
        setIsPaying(true);

        cardFieldsForm.submit().catch((err) => {
            setIsPaying(false);
        });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={isPaying}
            onClick={handleClick}
        >
            {isPaying ? <CircularProgress/> : "Paga"}
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    payButton: {
        margin: theme.spacing(2),
    },
    bankTransferDesc: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    otherPayment: {
        margin: theme.spacing(1),
    },
    expandedDetail: {
        display: 'flex',
        flexDirection: 'column',
        // marginBottom: theme.spacing(1),
    },
    expansionPanel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function Payment(props) {
    const [appState] = useContext(AppContext)
    const [isPaying, setIsPaying] = useState(false);
    const classes = useStyles()
    const [loading, setLoading] = useState(true)

    const {
        payError,
        paying,
        paid,
        onFinalizeBankTransferOrder,
        onBankTransfer,
        bankTransfer,
        step2Participation,
        division,
        paypalClientId,
        onMarkAsPaymentError,
        paypalAnswered,
        onPaypalAnswered,
        onCreateOrder,
        onCaptureOrder,
        amount,
    } = props
    const [expanded, setExpanded] = useState(bankTransfer)


    function onApprove(data) {
        console.info('Order Approved:', data.orderID);
        return onCaptureOrder(data.orderID).then((details) => {
            console.info('Payment Successful:', details);
            onPaypalAnswered(true);
        }).catch((error) => {
            console.error("Error capturing order:", error);
            onError(error);
        });
    }

    function onCreate(){
        return onCreateOrder(amount).then((orderID) => {
            console.info('Order Created:', orderID);
            return orderID;
        });
    }

    function onError(err) {
        console.log({onError: err})
        onPaypalAnswered(true)
        onMarkAsPaymentError(err)
    }

    const handleChange = event => {
        const {checked} = event.target
        if (checked) {
            setExpanded(true)
        }
        onBankTransfer(checked)
    }

    const handleExpansion = (event, expandedState) => {
        setExpanded(expandedState)
    }
    // type of text
    let textBankTransfer = {
        iban: '',
        bic: '',
    }

    const inpsPractice = (
        step2Participation === participationsLookup.INPSIEME ||
        step2Participation === participationsLookup.INPSNUOVO
    )

    if (division === '1') {
        // GSTUDY
        if (
            step2Participation === participationsLookup.INPSIEME ||
            step2Participation === participationsLookup.INPSNUOVO
        ) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario (il tuo posto è bloccato, effettua il bonifico entro 5 giorni per confermarlo)',
                title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (step2Participation === participationsLookup.PRIVATO) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (
            step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
        ) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                  title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT12V0311113501000000004631 (Ubi Banca)',
                bic: 'BLOPIT22',
            }
        }
    } else if (division === '2') {
        // LME
        if (
            step2Participation === participationsLookup.INPSIEME ||
            step2Participation === participationsLookup.INPSNUOVO
        ) {
            textBankTransfer = {
                //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario',
                iban: 'IT02F0306913507100000005144 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (step2Participation === participationsLookup.PRIVATO) {
            textBankTransfer = {
                //title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario ',
                iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
                bic: 'PASCITMMASP',
            }
        } else if (
            step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
        ) {
            textBankTransfer = {
                //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario',
                iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
                bic: 'PASCITMMASP',
            }
        }
    } else if (division === '3') {
        textBankTransfer = {
            /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',*/
            title:
                'Bonifico ordinario',
            iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
            bic: 'BCITITMM',
        }
    }

    const paypalMin = 30
    let paypalMax = 500
    if (division === '2') paypalMax = 300

    const isMain = false //(appState.applicationType === 'main')
    return (
        <>
            {!paid && (
                <Grid container spacing={3} className="panel">
                    {/*Il blocco del bonifico non dev'essere visualizzato se il form è il main*/}
                    {!paypalAnswered && !isMain && ( /* ripensamento Giocamondo: visualizzare i bonifici anche per flusso INPS */
                        <>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={10} className="payment-wrapper">
                                <>
                                    <ExpansionPanel
                                        className={classes.expansionPanel}
                                        expanded={expanded}
                                        onChange={handleExpansion}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-label="Expand"
                                            aria-controls="additional-actions1-content"
                                            id="additional-actions1-header">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={bankTransfer}
                                                        onChange={handleChange}
                                                        disabled={paying}
                                                        value="primary"
                                                        inputProps={{'aria-label': 'primary checkbox'}}
                                                    />
                                                }
                                                label={textBankTransfer.title}
                                            />
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expandedDetail}>
                                            <div align-items="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => onFinalizeBankTransferOrder()}
                                                    className={classes.payButton}
                                                    disabled={!bankTransfer || paying}>
                                                    Invia Prenotazione
                                                </Button>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </>
                    )}

                    {!bankTransfer && !!paypalClientId && (
                        <>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                className="payment-wrapper"
                                align-items="center">
                                {!bankTransfer && !paypalAnswered && !isMain && (
                                    <Typography variant="h6" className={classes.otherPayment}>
                                        Oppure conferma immediatamente con:
                                    </Typography>
                                )}
                                {isMain && <hr/>}
                                <Paper variant="outlined">
                                    <PayPalScriptProvider
                                        options={{
                                            clientId: paypalClientId,
                                            components: "buttons,card-fields,applepay,googlepay",
                                            currency: currency,
                                            enableFunding: 'paylater',
                                            commit: true,
                                        }}>
                                        <PayPalButtons
                                            createOrder={(_) => onCreate()}
                                            onApprove={(data) => onApprove(data)}
                                            onError={onError}
                                            onCancel={data => console.log({onCancel: data})}
                                            style={{
                                                shape: "rect",
                                                layout: "vertical",
                                                color: "gold",
                                                label: "paypal",
                                            }}
                                        />
                                        <PayPalCardFieldsProvider
                                            onError={onError}
                                            createOrder={() => onCreate()}
                                            onApprove={(data) => onApprove(data)}
                                            onCancel={data => console.log({onCancel: data})}
                                            style={{
                                                input: {
                                                    "font-size": "16px",
                                                    "font-family": "courier, monospace",
                                                    "font-weight": "lighter",
                                                    color: "#ccc",
                                                },
                                                ".invalid": { color: "purple" },
                                            }}
                                        >
                                            <PayPalNameField
                                                style={{
                                                    input: { color: "blue" },
                                                    ".invalid": { color: "purple" },
                                                }}
                                            />
                                            <PayPalNumberField />
                                            <PayPalExpiryField />
                                            <PayPalCVVField />
                                            <SubmitPayment
                                                isPaying={isPaying}
                                                setIsPaying={setIsPaying}
                                            />
                                        </PayPalCardFieldsProvider>
                                    </PayPalScriptProvider>
                                    {paypalAnswered && <CircularProgress/>}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </>
                    )}
                </Grid>
            )}
            {paid && !payError && (
                <div> Operazione di pagamento effettuata con successo </div>
            )}
        </>
    )
}

Payment.propTypes = {
    //state: PropTypes.object.isRequired,
    //billingAddress: PropTypes.object.isRequired,
    amount: PropTypes.string.isRequired,
    // email: PropTypes.string.isRequired,
    payError: PropTypes.any,
    paying: PropTypes.bool,
    paid: PropTypes.bool,
    onFinalizeBankTransferOrder: PropTypes.func.isRequired,
    onBankTransfer: PropTypes.func.isRequired,
    bankTransfer: PropTypes.bool,
    step2Participation: PropTypes.string,
    division: PropTypes.string,
    paypalClientId: PropTypes.string,
    onPaypalPaidOrder: PropTypes.func.isRequired,
    onMarkAsPaymentError: PropTypes.func.isRequired,
}
