import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import StepBonusInpsieme from './StepBonusInpsieme'
import StepBonusPrivate from './StepBonusPrivate'
import StepBonusCorporate from './StepBonusCorporate'
import {participations, participationsLookup} from '../../specificUtils/configs'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}))

export default function StepBonus(props) {
  const classes = useStyles()
  const {
    state,
    onEventChange,
    onSimpleCheckboxChange,
    onBonusRequest,
    onCheckboxChange,
  } = props
  const {
    division,
    step1ChildFiscalCode,
    step2Participation,
    step2InpsServiceId,
    step1ChildBirthDate,
    inpsServices,
    step2Grade,
    step2Class,
    step2bonuses,
    step2BonusRequested,
    step2BonusName,
    step2BonusSurname,
    step2BonusType,
    step2WelfareType,
    step2QCodiceCultura,
    step2AziendaConvenzionataNote,
    step2InpsServiceFlag,
    step2Coupon,
    step2VoucherCode,
    step2VoucherCodes,
    step2INPSnewQuestion,
    fixedPracticeInpsNumber,
    variablePracticeInpsNumber,
    errors,
  } = state

  return (
    <Grid container className={clsx(classes.root, 'step2-inner')} spacing={2}>
      <Grid item xs={12}>
        {step2Participation === participationsLookup.INPSIEME ||
        step2Participation === participationsLookup.INPSNUOVO ? (
          <StepBonusInpsieme
            division={division}
            inpsServices={inpsServices}
            participations={participations}
            step2bonuses={step2bonuses}
            step1ChildBirthDate={step1ChildBirthDate}
            step2Participation={step2Participation}
            step2InpsServiceId={step2InpsServiceId}
            step2Grade={step2Grade}
            step2Class={step2Class}
            step2InpsServiceFlag={step2InpsServiceFlag}
            step2BonusRequested={step2BonusRequested}
            step2BonusSurname={step2BonusSurname}
            step2BonusName={step2BonusName}
            onEventChange={onEventChange}
            onSimpleCheckboxChange={onSimpleCheckboxChange}
            onBonusRequest={onBonusRequest}
            step2Coupon={step2Coupon}
            step2VoucherCode={step2VoucherCode}
            step2VoucherCodes={step2VoucherCodes}
            step1ChildFiscalCode={step1ChildFiscalCode}
            step2QCodiceCultura={step2QCodiceCultura}
            step2INPSnewQuestion={step2INPSnewQuestion}
            fixedPracticeInpsNumber={fixedPracticeInpsNumber}
            variablePracticeInpsNumber={variablePracticeInpsNumber}
            errors={errors}
          />
        ) : step2Participation === participationsLookup.PRIVATO ? (
          <StepBonusPrivate
            division={division}
            step2bonuses={step2bonuses}
            step2BonusRequested={step2BonusRequested}
            step2BonusType={step2BonusType}
            step2BonusSurname={step2BonusSurname}
            step2BonusName={step2BonusName}
            onBonusRequest={onBonusRequest}
            onEventChange={onEventChange}
            step2Coupon={step2Coupon}
            step2VoucherCode={step2VoucherCode}
            step2VoucherCodes={step2VoucherCodes}
            step1ChildFiscalCode={step1ChildFiscalCode}
            errors={errors}
          />
        ) : (
          step2Participation === participationsLookup.AZIENDA_CONVENZIONATA && (
            <StepBonusCorporate
              step2WelfareType={step2WelfareType}
              step2AziendaConvenzionataNote={step2AziendaConvenzionataNote}
              onEventChange={onEventChange}
              onCheckboxChange={onCheckboxChange}
              step2VoucherCode={step2VoucherCode}
              step2VoucherCodes={step2VoucherCodes}
              step1ChildFiscalCode={step1ChildFiscalCode}
              errors={errors}
            />
          )
        )}
      </Grid>
    </Grid>
  )
}

StepBonus.propTypes = {
  state: PropTypes.object.isRequired,
  onEventChange: PropTypes.func.isRequired,
  onSimpleCheckboxChange: PropTypes.func.isRequired,
  onBonusRequest: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
}
